import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class AutologinService extends BaseService {

    constructor(protected http: HttpClient) {
        super(http);
    }

    getAutoLoginString(destinationUrl: string): Observable<any> {
        return this.http.get(`/api/autologin/getencryptedloginstring?destinationUrl=${destinationUrl}`)
            .pipe(catchError(this.handleError('getAutoLoginString')));
    }

    performAutoLogin(encryptedLoginString: string): Observable<any> {
        return this.http.post(`/api/autologin/performautologin?encryptedLoginString=${encodeURIComponent(encryptedLoginString) }`, this.httpOptions)
            .pipe(catchError(this.handleError('performAutoLogin')));
    }
}
